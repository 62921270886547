// Uncomment the following lines if you want to add a custom palette:
// $primary: #731963 !default;
// $secondary: #ce5712 !default;
// $app-palette: palette($primary, $secondary, $surface);

/* autoprefixer grid: on */

// @include core();
// @include typography($font-family: $material-typeface, $type-scale: $material-type-scale);
// @include theme($default-palette);
// CSS Reset, comment out if not required or using a different module
@import 'minireset.css/minireset';
/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.mr-16 {
    margin-right: 16px;
}

.mt-32 {
    margin-top: 32px;
}

.mb-16 {
  margin-bottom: 32px;
}

.full-width {
    width: 100%;
}

.of-visible {
    overflow: visible !important;
}

.border-bottom-grey {
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
}

.pos-right-upper {
  position: absolute !important;
  right: 8px;
  top: 8px;
}
